<template>
    <v-container>
        <h2>Seguros</h2>
        <v-row>
            <v-col cols="6">
                <CapsVTextField v-model="searchText" :placeholder="'Búsqueda'"></CapsVTextField>
            </v-col>
            <v-col cols="6">
                <v-select v-if="hasPermission('Seguros', 'create')" v-model="searchDoyangId" :items="doyangs"
                    item-text="name" item-value="id" label="Doyang" outlined></v-select>
            </v-col>
        </v-row>
        <v-tabs v-model="insuranceActiveTab">
            <v-tab v-for="(label, index) in tabLabels" :key="index">
                {{ label.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="insuranceActiveTab">
            <!--Asegurables-->
            <v-tab-item>
                <p>
                    Aquellas personas que no están aseguradas pero ya pagaron su seguro.
                </p>
                <v-data-table v-if="insurables" :items="insurables" :headers='[
                    { value: "full_name", text: "Nombre" },
                    { value: "parsed_insurance_end_date", text: "Fin de seguro" },
                    { value: "doyang.name", text: "Doyang" },
                    { value: "actions", text: "Acciones" },
                ]' :itemsPerPage="this.paginated && this.paginated.insurables ? this.paginated.insurables.per_page : 5"
                    :server-items-length="this.paginated && this.paginated.insurables ? this.paginated.insurables.total : 0"
                    @update:page="(pageNum) => { loadUsers('insurables', pageNum) }">
                    <template v-slot:item.actions="{ item }">
                        <v-btn color="primary" @click="sendToInsurance(item)">Enviar</v-btn>
                        <v-btn v-if="item.is_rejected_insurance_file" color="primary"
                            @click="downloadRejectionDocument(item)"><v-icon>mdi-image</v-icon> Documento</v-btn>
                    </template>
                </v-data-table>
            </v-tab-item>
            <!--No Asegurables-->
            <v-tab-item>
                <p>
                    Aquellas personas que no están aseguradas ni han pagado su seguro.
                </p>
                <v-data-table v-if="non_insurables" :items="non_insurables" :headers='[
                    { value: "full_name", text: "Nombre" },
                    { value: "parsed_insurance_end_date", text: "Fin de seguro" },
                    { value: "doyang.name", text: "Doyang" },
                    { value: "actions", text: "Acciones" },
                ]' :itemsPerPage="this.paginated && this.paginated.non_insurables ? this.paginated.non_insurables.per_page : 5"
                    :server-items-length="this.paginated && this.paginated.non_insurables ? this.paginated.non_insurables.total : 0"
                    @update:page="(pageNum) => { loadUsers('non_insurables', pageNum) }">
                    <template v-slot:item.actions="{ item }">
                        <v-btn color="warning" @click="openRejectionDialog(item)">Rechazar</v-btn>
                        <v-btn v-if="item.is_rejected_insurance_file" color="primary"
                            @click="downloadRejectionDocument(item)"><v-icon>mdi-image</v-icon> Documento</v-btn>
                    </template>
                </v-data-table>
            </v-tab-item>
            <!--Enviados-->
            <v-tab-item>
                <p>
                    Las personas que ya pagaron su seguro y éste fue enviado. Están en espera de recibir una póliza.
                </p>
                <v-btn @click="printSent" class="primary" style="color: white;"><v-icon>mdi-printer</v-icon> Imprimir
                    enviados</v-btn>
                <v-data-table v-if="insurances_sent" :items="insurances_sent" :headers='[
                    { value: "full_name", text: "Nombre" },
                    { value: "parsed_insurance_end_date", text: "Fin de seguro" },
                    { value: "doyang.name", text: "Doyang" },
                    { value: "actions", text: "Acciones" },
                ]' :itemsPerPage="this.paginated && this.paginated.insurances_sent ? this.paginated.insurances_sent.per_page : 5"
                    :server-items-length="this.paginated && this.paginated.insurances_sent ? this.paginated.insurances_sent.total : 0"
                    @update:page="(pageNum) => { loadUsers('insurances_sent', pageNum) }">
                    <template v-slot:item.actions="{ item }">
                        <v-btn color="primary" @click="openInsuranceDialog(item)">Asegurar</v-btn>
                    </template>
                </v-data-table>
            </v-tab-item>
            <!--Asegurados-->
            <v-tab-item>
                <p>
                    Personas que tienen una póliza activa.
                </p>
                <v-btn @click="printInsuranced" class="primary" style="color: white;"><v-icon>mdi-printer</v-icon>
                    Imprimir
                    asegurados</v-btn>
                <v-data-table v-if="insuranced" :items="insuranced" :headers='[
                    { value: "full_name", text: "Nombre" },
                    { value: "parsed_insurance_date", text: "Fecha de seguro" },
                    { value: "parsed_insurance_end_date", text: "Fin de seguro" },
                    { value: "doyang.name", text: "Doyang" },
                    { value: "insurance_number", text: "Número" },
                    { value: "actions", text: "Acciones" },
                ]' :itemsPerPage="this.paginated && this.paginated.insuranced ? this.paginated.insuranced.per_page : 5"
                    :server-items-length="this.paginated && this.paginated.insuranced ? this.paginated.insuranced.total : 0"
                    @update:page="(pageNum) => { loadUsers('insuranced', pageNum) }">
                    <template v-slot:item.actions="{ item }">
                        <v-icon color="primary" @click="editInsuranced(item)">mdi-pencil</v-icon>
                    </template>
                </v-data-table>
            </v-tab-item>
            <!--Rechazaron el seguro-->
            <v-tab-item>
                <p>
                    Personas que rechazaron el seguro.
                </p>
                <v-data-table v-if="rejected" :items="rejected" :headers='[
                    { value: "full_name", text: "Nombre" },
                    { value: "parsed_insurance_end_date", text: "Fin de seguro" },
                    { value: "parsed_rejected_insurance_at", text: "Fecha de rechazo" },
                    { value: "doyang.name", text: "Doyang" },
                    { value: "actions", text: "Acciones" },
                ]' :itemsPerPage="this.paginated && this.paginated.rejected ? this.paginated.rejected.per_page : 5"
                    :server-items-length="this.paginated && this.paginated.rejected ? this.paginated.rejected.total : 0"
                    @update:page="(pageNum) => { loadUsers('rejected', pageNum) }">
                    <template v-slot:item.actions="{ item }">
                        <v-btn color="primary" @click="downloadRejectionDocument(item)"><v-icon>mdi-image</v-icon>
                            Documento</v-btn>
                        <v-btn color="green lighten-2" dark
                            @click="removeFromRejectionInsurance(item)"><v-icon>mdi-check</v-icon>
                            Aceptaron el seguro</v-btn>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>

        <v-dialog v-model="isRejectedInsuranceDialogOpen" max-width="600">
            <v-card>
                <v-card-title>
                    <h2>Rechazó el seguro</h2>
                </v-card-title>
                <v-card-text>
                    <v-file-input v-model="rejected_insurance_file" accept="application/pdf,image/*" label="Archivo"
                        outlined></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="isRejectedInsuranceDialogOpen = false">Cancelar</v-btn>
                    <v-btn color="warning" v-if="hasPermission('Seguros', 'update')"
                        @click="uploadRejectInsuranceFile()">Subir
                        archivo</v-btn>
                    <v-btn v-if="hasPermission('Seguros', 'create')" :disabled="isLoadingRejection" color="warning"
                        @click="rejectInsuranceStudent()">Rechazar seguro</v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isInsuranceDialogOpen">
            <v-card>
                <v-card-title>
                    <h2>Asegurar</h2>
                </v-card-title>
                <v-card-text>
                    <CapsVTextField v-model="dialogInsuranceNumber" :placeholder="'Número de seguro'"></CapsVTextField>
                    <DMYDateField v-model="dialogInsuranceDate" label="Fecha de seguro"></DMYDateField>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="insuranceStudent()">Asegurar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import ENV from '../../../env';
var debounce = require('debounce');
export default {
    mounted() {
        this.loadUsers('insurables');
        this.$store.dispatch('Doyang/index');
    },
    methods: {
        removeFromRejectionInsurance(item) {
            this.$store.dispatch('Student/update', {
                id: item.id,
                is_rejected_insurance: false,
                comes_from_insurances: true,
            }).then(() => {
                this.rejected = this.rejected.filter((user) => user.id !== item.id);
                this.insurables.push(item);
            });
        },
        editInsuranced(item) {
            const insurance_number = prompt('Número de seguro', item.insurance_number);
            const sendItem = {
                id: item.id,
                insurance_number,
            }
            this.$store.dispatch('Insurance/update', sendItem).then(() => {
                this.insuranced = this.insuranced.map(insurable => {
                    if (insurable.id == item.id) {
                        insurable.insurance_number = insurance_number;
                    }
                    return insurable;
                });
            }).catch((e) => {
                const message = e?.response?.data?.message;
                alert(message || 'Hubo un error actualizando el número de seguro, favor de reintentar,')
            });
        },
        downloadRejectionDocument(item) {
            this.$store.dispatch('Insurance/downloadRejectionDocument', item.id)
        },
        printSent() {
            const doyangIdString = this.selectedDoyang?.id ? `doyang_id=${this.selectedDoyang.id}` : ``;
            this.$store.dispatch('Insurance/printSheet', `?${doyangIdString}&insurances_sent=1`).then((data) => {
                this.__downloadStringInNewPage(data, 'seguros_enviados.csv');
            });
        },
        printInsuranced() {
            const doyangIdString = this.selectedDoyang?.id ? `doyang_id=${this.selectedDoyang.id}` : ``;
            this.$store.dispatch('Insurance/printSheet', `?${doyangIdString}&insuranced=1`).then((data) => {
                this.__downloadStringInNewPage(data, 'alumnos_asegurados.csv');
            });
        },
        __downloadStringInNewPage(string, filename) {
            const blob = new Blob([string], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        openInsuranceDialog(student) {
            this.insuranceDialogStudent = student;
            this.isInsuranceDialogOpen = true;
        },
        loadUsers(type, pageNum = 1) {
            if (`loaded_${type}` == true) {
                return;
            }
            this[`loaded_${type}`] = true;
            const searchTextUrl = this.searchText ? `&search=${this.searchText}` : '';
            let doyangIdString = this.selectedDoyang?.id ? `doyang_id=${this.selectedDoyang.id}` : ``;
            if (this.searchDoyangId) {
                doyangIdString = `doyang_id=${this.searchDoyangId}`;
            }
            this.$store.dispatch('Insurance/index', `?append=parsed_insurance_date,parsed_insurance_end_date&${doyangIdString}&${type}=1&page=${pageNum}${searchTextUrl}`).then((data) => {
                this[type] = data.data;
                this.paginated = {
                    ...this.paginated,
                    [type]: data,
                };
            });
        },
        openRejectionDialog(student) {
            this.studentRejecting = student;
            this.isRejectedInsuranceDialogOpen = true;
        },
        hasPermission(name, action = false) {
            const names = name.split(",")
            if (names.length > 1) {
                let hasPermission = false
                names.forEach(name => {
                    if (hasPermission) return;
                    hasPermission = this.hasPermission(name, action)
                })
                return hasPermission
            }
            if (!name) return false;
            const permissions = this.authInfo?.user?.permissions
            if (!permissions) return false
            const singlePermissionkey = Object.keys(permissions).filter(key => {
                return permissions[key].name === name
            })?.[0]
            const singlePermission = permissions?.[singlePermissionkey]
            if (!singlePermissionkey) return false
            if (!action && singlePermission) {
                return (singlePermission?.create || singlePermission?.read || singlePermission?.update || singlePermission?.delete) ? true : false
            }
            return singlePermission?.[action] == 1 || false
        },
        uploadRejectInsuranceFile() {
            const formData = new FormData();
            const student = this.studentRejecting;
            formData.append('is_rejected_insurance', false);
            formData.append('is_rejected_insurance_file', this.rejected_insurance_file);
            formData.append('id', student.id);
            formData.append('comes_from_insurances', true);
            this.$store.dispatch('Student/create', formData).then(() => {
                this.isRejectedInsuranceDialogOpen = false;
                this.rejected_insurance_file = null;
                this.insurables = this.insurables.map(insurable => {
                    if (insurable.id == student.id) {
                        insurable.is_rejected_insurance_file = true;
                    }
                    return insurable;
                });
            });
        },
        rejectInsuranceStudent() {
            const student = this.studentRejecting;
            const formData = new FormData();
            formData.append('is_rejected_insurance', true);
            formData.append('is_rejected_insurance_file', this.rejected_insurance_file);
            formData.append('id', student.id);
            formData.append('comes_from_insurances', true);
            this.isLoadingRejection = true;
            this.$store.dispatch('Student/create', formData).then(() => {
                this.non_insurables = this.non_insurables.filter((user) => user.id !== student.id);
                this.insurables = this.insurables.filter((user) => user.id !== student.id);
                this.rejected.push(student);
                this.isRejectedInsuranceDialogOpen = false;
                this.rejected_insurance_file = null;
                this.isLoadingRejection = false;
            }).catch((e) => {
                this.isLoadingRejection = false;
                const message = e?.response?.data?.message;
                alert(message || 'Hubo un error subiendo el archivo, favor de reintentar,')
            });
        },
        sendToInsurance(student) {
            this.$store.dispatch('Student/update', {
                id: student.id,
                insurance_sent_date: true,
                comes_from_insurances: true,
            }).then(() => {
                this.insurables = this.insurables.filter((user) => user.id !== student.id);
                this.insurances_sent.push(student);
            });
        },
        insuranceStudent(student) {
            student = student || this.insuranceDialogStudent;
            if (!this.dialogInsuranceDate) {
                alert('Favor de seleccionar una fecha de seguro');
                return;
            }
            this.$store.dispatch('Student/update', {
                id: student.id,
                insurance_end_date: true,
                insurance_number: this.dialogInsuranceNumber,
                insurance_date: this.dialogInsuranceDate,
                comes_from_insurances: true,
            }).then(() => {
                this.dialogInsuranceNumber = null;
                this.dialogInsuranceDate = null;
                this.insurances_sent = this.insurances_sent.filter((user) => user.id !== student.id);
                this.isInsuranceDialogOpen = false;
                this.insuranced.push(student);
            });
        },
        parseDate(date) {
            return ENV.__parseDateToTextYMD(date);
        },
        allInsurances() {
            return this.paginated?.data;
        },
    },
    watch: {
        insuranceActiveTab(val) {
            const type = this.tabLabels[val].value;
            this.loadUsers(type);
        },
        searchDoyangId() {
            const activeTab = this.tabLabels[this.insuranceActiveTab]
            const value = activeTab.value
            this.loadUsers(value);
        },
        searchText: debounce(function () {
            const activeTab = this.tabLabels[this.insuranceActiveTab];
            const value = activeTab.value;
            this.loadUsers(value);
        }, 500)
    },
    computed: {
        doyangs() {
            return this.$store.getters['Doyang/doyangs'] || []
        },
        authInfo() {
            return this.$store.getters['Auth/authInfo']
        },
        selectedDoyang() {
            return this.$store.getters["Auth/selectedDoyang"];
        }
    },
    data() {
        return {
            isLoadingRejection: false,
            searchDoyangId: null,
            searchText: '',
            loaded_insurables: false,
            loaded_non_insurables: false,
            loaded_insurances_sent: false,
            loaded_insuranced: false,
            loaded_rejected: false,
            dialogInsuranceNumber: null,
            dialogInsuranceDate: null,
            isInsuranceDialogOpen: false,
            insuranceDialogStudent: null,
            tabLabels: [
                { text: 'Asegurables', value: 'insurables' },
                { text: 'No asegurables', value: 'non_insurables' },
                { text: 'Enviados', value: 'insurances_sent' },
                { text: 'Asegurados', value: 'insuranced' },
                { text: 'Rechazaron el seguro', value: 'rejected' },
            ],
            studentRejecting: null,
            rejected_insurance_file: null,
            isRejectedInsuranceDialogOpen: false,
            insuranceActiveTab: 0,
            insurances: [],
            paginated: {},
            insurables: [],
            non_insurables: [],
            insurances_sent: [],
            insuranced: [],
            rejected: [],
        }
    }
}
</script>
